import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/builds/labs/labs.mbanq.io/src/components/blog/Post.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`New Batch - New Program`}</h1>
    <p>{`Lately we've been working hard on drafting a new program for our 2nd batch of startups.`}</p>
    <p>{`In the next iteration of our program we will concentrate on following aspects of the starup success:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Marketing`}</strong>{` - `}<em parentName="li">{`If you build it, they will come`}</em>{` never works. In order for your startup to be successful, you have to be known. It's important to get new customers, to get funding and to grow.
known`}</li>
      <li parentName="ul"><strong parentName="li">{`Product`}</strong>{` - Once they will come, you should have a product they were looking for. We will help you to build the product that is `}<em parentName="li">{`valuable`}</em>{` to your customers, `}<em parentName="li">{`usable`}</em>{` and `}<em parentName="li">{`feasible`}</em>{` to build (`}<a parentName="li" {...{
          "href": "https://www.goodreads.com/book/show/3323374-inspired"
        }}>{`https://www.goodreads.com/book/show/3323374-inspired`}</a>{`)`}</li>
      <li parentName="ul"><strong parentName="li">{`Software Engineering`}</strong>{` - If you want to build a successful company, you have to build a solid foundation. We'll help you to lay the foundation of your future highly scalable platform.`}</li>
      <li parentName="ul"><strong parentName="li">{`Growth`}</strong>{` - Whether you would like to conquer new markets or want to accelerate the growth in a certain region - we'll here to help.`}</li>
    </ul>
    <p>{`We want our program to spread the practical knowledge and not only the theoretical one. We want to help you figuring out what you have to do in order to get successful and provide value to your customers.`}</p>
    <p>{`If you're interested in knowing more, follow us on our social media channel:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.linkedin.com/company/mbanq-labs"
        }}>{`LinkedIn`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.facebook.com/baasislabs"
        }}>{`Facebook`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://www.instagram.com/baasis_labs"
        }}>{`Instagram`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://twitter.com/Mbanqcom"
        }}>{`Twitter`}</a></li>
    </ul>
    <p>{`Soon we'll post more information about the upcoming program, introduce our new mentors and partners.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      